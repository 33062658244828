export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは1月より%d点上がりました。年度末で忙しい3月もこの調子で！<br>
      今月は「<b>＆well FESTA 2022</b>」を開催！ワークショップや年間賞の表彰式などイベントが目白押し。ポイント交換キャンペーンも豪華賞品が勢ぞろいです。応募期間は3月11日～3月19日、詳細はアプリにてご確認ください。
      `,
      down: `生活習慣スコアは1月より%d点下がりました。食事・運動・睡眠など、どの項目に変化がありましたか？<br>
      今月は「<b>＆well FESTA 2022</b>」を開催！ワークショップや年間賞の表彰式などイベントが目白押し。ポイント交換キャンペーンも豪華賞品が勢ぞろいです。応募期間は3月11日～3月19日、詳細はアプリにてご確認ください。
      `,
      equal100: `生活習慣スコアは1月に引き続き%満点%です。連続して満点ですね！大変素晴らしいです！<br>
      今月は「<b>＆well FESTA 2022</b>」を開催！ワークショップや年間賞の表彰式などイベントが目白押し。ポイント交換キャンペーンも豪華賞品が勢ぞろいです。応募期間は3月11日～3月19日、詳細はアプリにてご確認ください。
      `,
      equal: `生活習慣スコアは1月と同じ点数です。<br>
      今月は「<b>＆well FESTA 2022</b>」を開催！ワークショップや年間賞の表彰式などイベントが目白押し。ポイント交換キャンペーンも豪華賞品が勢ぞろいです。応募期間は3月11日～3月19日、詳細はアプリにてご確認ください。
      `,
    },
    steps: {
      up: `歩数スコアは1月より%d点上がりました。<br>
      寒い時期もよく歩けていますね！ 歩くことはストレス解消にも役立ちますよ。その調子で！
      `,
      down: `歩数スコアは1月より%d点下がりました<br>
      春の訪れを感じる3月。暖かい日差しを感じながら、散歩するのも気持ちよいですよ♪
      `,
      equal: `歩数スコアは1月と同じです。<br>
      春の訪れを感じる3月。暖かい日差しを感じながら、散歩するのも気持ちよいですよ♪
      `,
    },
    exercise: {
      up: `運動のスコアは1月より%d点上がりました。<br>
      活動的になる春に向けて、そろそろ準備運動！<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a>の動画を見ながら<b>10分間</b>だけ身体を動かしてみませんか？`,
      down: `運動のスコアは1月より%d点下がりました。<br>
      活動的になる春に向けて、そろそろ準備運動！<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a>の動画を見ながら<b>10分間</b>だけ身体を動かしてみませんか？`,
      equal: `運動のスコアは1月と同じ点数です。<br>
      活動的になる春に向けて、そろそろ準備運動！<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a>の動画を見ながら<b>10分間</b>だけ身体を動かしてみませんか？`,
    },
    meal: {
      up: `食事のスコアは1月より%d点上がりました。<br>
      忙しい時期は、ついつい早食いになりやすいです。<b>1食15分以上</b>かけるようにすると満腹感が得られやすくなります。
      `,
      down: `食事のスコアは1月より%d点下がりました。<br>
      忙しい時期は、ついつい早食いになりやすいです。<b>1食15分以上</b>かけるようにすると満腹感が得られやすくなります。
      `,
      equal: `食事のスコアは1月と同じ点数です。<br>
      忙しい時期は、ついつい早食いになりやすいです。<b>1食15分以上</b>かけるようにすると満腹感が得られやすくなります。
      `,
    },
    drinking: {
      up: `飲酒のスコアは1月より%d点上がりました。<br>
      お酒を適度に飲むことは、ストレス緩和にもつながります。飲み過ぎないように気を付けてくださいね。
      `,
      down: `飲酒のスコアは1月より%d点下がりました。<br>
      <a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">＜こちら＞</a>の健康コラムを読んで、自分に合った「適正飲酒量」を考えてみましょう。
      `,
      equal: `飲酒のスコアは1月と同じ点数です。<br>
      <a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">＜こちら＞</a>の健康コラムを読んで、自分に合った「適正飲酒量」を考えてみましょう。
      `,
    },
    sleep: {
      up: `睡眠のスコアは1月より%d点上がりました。<br>
      3月18日は「<b>春の睡眠の日</b>」しっかりした朝食と適度な運動をして、眠りと目覚めのメリハリをつけましょう。
      `,
      down: `睡眠のスコアは1月より%d点下がりました。<br>
      3月18日は「<b>春の睡眠の日</b>」しっかりした朝食と適度な運動をして、眠りと目覚めのメリハリをつけましょう。
      `,
      equal: `睡眠のスコアは1月と同じ点数です。<br>
      3月18日は「<b>春の睡眠の日</b>」しっかりした朝食と適度な運動をして、眠りと目覚めのメリハリをつけましょう。
      `,
    },
    stress: {
      up: `ストレスのスコアは1月より%d点上がりました。<br>
      人は誰でも変化に対していつもと同じ状態に維持しようとする傾向があります。リラックスしたい時に、「フーッ」と息を吐いてみましょう。
      `,
      down: `ストレスのスコアは1月より%d点下がりました。<br>
      人は誰でも変化に対していつもと同じ状態に維持しようとする傾向があります。リラックスしたい時に、「フーッ」と息を吐いてみましょう。
      `,
      equal: `ストレスのスコアは1月と同じ点数です。<br>
      人は誰でも変化に対していつもと同じ状態に維持しようとする傾向があります。リラックスしたい時に、「フーッ」と息を吐いてみましょう。
      `,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up by %d pts since January. Keep it up in March, the busiest month of the year!<br>
      This month we're hosting <b>&well FESTA 2022<b>! There'll be a lot of events such as workshops and the annual award ceremony. The Point Redemption Campaign also offers a variety of gorgeous prizes. The application period is from March 11 to March 19. Check out the application for more details.
      `,
      down: `Your lifestyle score has gone down by %d pts since January. Which of the following changed: diet, exercise, sleep, etc.?<br>
      This month we're hosting <b>&well FESTA 2022<b>! There'll be a lot of events such as workshops and the annual award ceremony. The Point Redemption Campaign also offers a variety of gorgeous prizes. The application period is from March 11 to March 19. Check out the application for more details.
      `,
      equal100: `Your lifestyle score is the %maximum% again, the same as January. That's a perfect score for consecutive months! Very impressive!<br>
      This month we're hosting <b>&well FESTA 2022<b>! There'll be a lot of events such as workshops and the annual award ceremony. The Point Redemption Campaign also offers a variety of gorgeous prizes. The application period is from March 11 to March 19. Check out the application for more details.
      `,
      equal: `Your lifestyle score is the same as January.<br>
      This month we're hosting <b>&well FESTA 2022<b>! There'll be a lot of events such as workshops and the annual award ceremony. The Point Redemption Campaign also offers a variety of gorgeous prizes. The application period is from March 11 to March 19. Check out the application for more details.
      `,
    },
    steps: {
      up: `Your steps score has gone up %d pts since January.<br>
      You walk a lot, even in cold weather! Walking helps relieve stress, too. Keep it up!
      `,
      down: `Your steps score has gone down %d pts since January. <br>
      March is the month of spring.  It's pleasant to take a walk while feeling the warm sunlight.
      `,
      equal: `Your steps score is the same as January. <br>
      March is the month of spring.  It's pleasant to take a walk while feeling the warm sunlight.
      `,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since January.<br>
      It's time to get ready for spring, the perfect season to be more active! Watch the <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a> video to help you get started.
      `,
      down: `Your exercise score has gone down %d pts since January.<br>
      It's time to get ready for spring, the perfect season to be more active! Watch the <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a> video to help you get started.
      `,
      equal: `Your exercise score is the same as January.<br>
      It's time to get ready for spring, the perfect season to be more active! Watch the <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a> video to help you get started.
      `,
    },
    meal: {
      up: `Your food score has gone up %d pts since January.<br>
      It's easy to eat too quickly when you're in a hurry. Spending <b>at least 15 minutes</b> per meal will help you feel fuller.
      `,
      down: `Your food score has gone down %d pts since January.<br>
      It's easy to eat too quickly when you're in a hurry. Spending <b>at least 15 minutes</b> per meal will help you feel fuller.
      `,
      equal: `Your food score is the same as January.<br>
      It's easy to eat too quickly when you're in a hurry. Spending <b>at least 15 minutes</b> per meal will help you feel fuller.
      `,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since January.<br>
      Drinking alcohol in moderation can also help relieve stress—be careful not to drink too much!
      `,
      down: `Your alcohol score has gone down %d pts since January.<br>
      Read the health column <a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">here</a> to help you decide an amount that's best for you.
      `,
      equal: `Your alcohol score is the same as January.<br>
      Read the health column <a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">here</a> to help you decide an amount that's best for you.
      `,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since January.<br>
      March 18 is "<b>Spring Sleep Day</b>". Let's start the day off right with a good night's rest, a complete breakfast, and a bit of exercise.
      `,
      down: `Your sleep score has gone down %d pts since January.<br>
      March 18 is "<b>Spring Sleep Day</b>". Let's start the day off right with a good night's rest, a complete breakfast, and a bit of exercise.
      `,
      equal: `Your sleep score is the same as January.<br>
      March 18 is "<b>Spring Sleep Day</b>". Let's start the day off right with a good night's rest, a complete breakfast, and a bit of exercise.
      `,
    },
    stress: {
      up: `Your stress score has gone up %d pts since January.<br>
      We all have a tendency to try to maintain the same state of mind as usual in response to change. Take a deep breath and exhale slowly to help you relax.
      `,
      down: `Your stress score has gone down %d pts since January.<br>
      We all have a tendency to try to maintain the same state of mind as usual in response to change. Take a deep breath and exhale slowly to help you relax.
      `,
      equal: `Your stress score is the same as January.<br>
      We all have a tendency to try to maintain the same state of mind as usual in response to change. Take a deep breath and exhale slowly to help you relax.
      `,
    },
  },
}
